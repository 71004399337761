import { SubmitHandler, useFormContext } from 'react-hook-form';
import * as Yup from 'yup';

import { Note } from '../../../generated/types';
import { Colors } from '../../../styles/colors';

import { Form } from '../../atoms/Form/Form';
import { PrimaryButton, SecondaryButton, WrapperButtons } from '../../atoms/Button/Buttons';
import { H2 } from '../../atoms/Typography/Headings';
import { AlertType, InfoAlert } from '../../atoms/InfoAlert';
import { TextInput } from '../../atoms/Form/TextInput';
import { ChevronRightIcon } from '../../atoms/Icons';
import { Copy } from '../../atoms/Typography';
import { RichTextInput } from '../../atoms/Form/RichTextInput';

export const AddNoteModal = ({ note, handleCancel, handleSubmit }: AddNoteModalProps) => {
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    handleSubmit(data, note ? note : undefined);
  };

  return (
    <>
      {/* <pre>{JSON.stringify(note, null, 2)}</pre> */}
      <H2>{note ? 'Edit Note' : 'Add Note'}</H2>
      <InfoAlert>
        <Copy>
          {note
            ? 'You can edit this note related to this Job Opportunity.'
            : 'You can add a note related to this Job Opportunity.'}
        </Copy>
      </InfoAlert>
      <Form onSubmit={onSubmit} validationSchema={validationSchema}>
        <TextInput name="title" defaultValue={note?.title || ''} type="text" label="Title" />

        <RichTextInput
          name="description"
          defaultValue={note?.description || ''}
          type="textarea"
          label="Description"
          multiline={true}
          minRows={5}
        />

        <WrapperButtons>
          <SecondaryButton inline onClick={handleCancel}>
            Cancel
          </SecondaryButton>
          {note ? (
            <PrimaryButton type="submit" inline={true} iconRight={<ChevronRightIcon color={Colors.White} size={1} />}>
              Update
            </PrimaryButton>
          ) : (
            <PrimaryButton type="submit" inline={true} iconRight={<ChevronRightIcon color={Colors.White} size={1} />}>
              Add
            </PrimaryButton>
          )}
        </WrapperButtons>
      </Form>
    </>
  );
};

const validationSchema = Yup.object().shape({
  body: Yup.string(),
  title: Yup.string(),
});

type FormData = Yup.InferType<typeof validationSchema>;

interface AddNoteModalProps {
  note?: Note | null;
  handleSubmit: (a: any, e?: Note) => void;
  handleCancel: (a: any) => void;
}
